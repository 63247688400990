h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}
html {
	font-family: $mainFont;
	font-weight: 500;
	font-size: 16px;
	line-height: 27px;
    scroll-behavior: smooth;
}
body.Bodymotionless {
	width: 100vw;
	min-height: 100vh;
	overflow: hidden;
  }

* {
	margin: 0;
	padding: 0;
    box-sizing: border-box;
}
html,
body {
    height: 100%;
    display: flex;
	flex-direction: column;
	min-height: 100%;
}
a, button {
    cursor: pointer;
    text-decoration: none;
}
.site-main {
	flex: 1 0 auto;
    overflow: hidden;
    width: 100%;
}
.site-footer{
	flex: 0 0 auto;
}

.container {
    position: relative;
	box-sizing: border-box;
	width: 1450px;
	max-width: calc(100% - 60px);
	margin: 0 auto;
    position: relative;
    @include max(xl) {
        width: 100%;
        max-width: calc(100% - 30px);
    }
    @include max(lg) {
        width: 100%;
        max-width: calc(100% - 20px);
    }
}
.section {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    background: #fff;
    &--grey {
        background: #F5F5F5;
    }
}
.title__50 {
    color: rgb(255, 255, 255);
    font-size: 50px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0%; 
    text-align: left;
    @include max(sm) {
        font-size: 40px;
        font-weight: 500;
        line-height: 110%;
        letter-spacing: 0%; 
    }  
}
.title__40 {
    color: $fontColor;

    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0%;
    text-align: left;
    @include max(sm) {
        font-size: 25px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0%; 
    }
}
.title__30 {
    color: $fontColor;

    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0%;
    text-align: left;
    @include max(sm) {
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0%;
    }
}
.title__25 {
    color: $fontColor;

    font-size: 25px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0%;
    text-align: left;
    @include max(sm) {
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0%;
    }
}
.text__20 {
    color: rgb(255, 255, 255);

    font-size: 20px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0%;
    text-align: left; 
    @include max(sm) {
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0%;
    }
}
.text__17 {
    color: $fontColor;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    @include max(sm) {
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0%;
    }
    
}
.text__16 {
    color: $fontColor;

    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0%;
    text-align: left;
    @include max(sm) {
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0%;
    }
    
}
.btn-new {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: rgb(51, 51, 51);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(51, 51, 51);
    font-size: 15px;
    font-weight: 500;
    line-height: 0;
    letter-spacing: 0%;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s;
    padding: 12px 30px;
    width: fit-content;
    min-height: 42px ;
    transition: all 0.3s;
    @include max(sm) {
        padding: 12px 15px;
        font-size: 11px;
    }
    &:hover {
        transition: all 0.3s;
        background: #fff;
        border: 1px solid rgb(51, 51, 51);
        color: rgb(51, 51, 51);
    }
    &--red-full {
        background: rgb(202, 33, 71);
        border: 1px solid rgb(202, 33, 71); 
    }
    &--red {
        transition: all 0.3s;
        background: #fff;
        border: 1px solid rgb(202, 33, 71);
        color: rgb(202, 33, 71);
        &:hover {
            transition: all 0.3s;
            background: #fff;
            border: 1px solid rgb(51, 51, 51);
            color: rgb(51, 51, 51);
        }
    }
}
.banner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1920px;
    background-image: url(../img/banner__bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 393px;
    @include max(md) {
        padding: 20px;
    }
    &--credit-page {
        margin-bottom: 140px;
        .banner__picture {
            @include max(md) {
                top: 40px;
            }
        }
    }
    &--delivery-page {
        margin-bottom: 190px;
        @include max(sm) {
            margin-bottom: 80px;
        }
        .banner__picture {
            z-index: 20;
            @include max(md) {
                top: 40px;
            }
            @include max(sm) {
                top: 40px;
            }
            &__line {
                position: absolute;
                display: block;
                left: -70%;
                bottom: 18%;
                z-index: 0;
                @include max(md) {
                   display: none;
                }
            }
        }
    }
    &--refund-page {
        margin-bottom: 129px;

        @include max(sm) {
            margin-bottom: 80px;
        }
        .banner__picture {
            z-index: 20;
            top: 40px;
            @include max(md) {
                top: 40px;
            }
            @include max(sm) {
                top: 40px;
            }
        }
    }
    &--payment-page {
        margin-bottom: 45px;
        @include max(sm) {
            margin-bottom: 40px;
        }
        .banner__picture {
            z-index: 20;
            top: 10px;
            @include max(md) {
                top: 40px;
            }
            @include max(sm) {
                top: 40px;
            }
        }
    }
    &--warranty-page {
        margin-bottom: 50px;
        @include max(md) {
            margin-bottom: 80px;
        }
        .banner__picture {
            z-index: 20;
            top: 10px;
            @include max(md) {
                top: 40px;
            }
            @include max(sm) {
                top: 40px;
            }
        }
    }
    &--credit-tenkoff {
        background-image: url(../img/banner__credit-tenkoff__1.png);
        min-height: 400px;
        padding: 0 !important;
        margin-bottom: 64px;
        @include max(md) {
            margin-bottom: 40px;
        }
        .banner__picture {
            z-index: 20;
            padding: 0;
            top: 0px;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            overflow: hidden;
            @include max(md) {
                top: 0px;
            }
            @include max(sm) {
                top: 0px;
            }
            img {
                @include max(sm) {
                    object-fit: cover;
                    object-position: top left;
                }
            }
        }
    }
    &--credit-sber {
        background-image: url(../img/banner__credit-sber__1.png);
        min-height: 400px;
        padding: 0 !important;
        margin-bottom: 83px;
        @include max(md) {
            margin-bottom: 40px;
        }
        .banner__picture {
            z-index: 20;
            padding: 0;
            top: 0px;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            overflow: hidden;
            @include max(md) {
                top: 0px;
            }
            @include max(sm) {
                top: 0px;
            }
            img {
                @include max(sm) {
                    object-fit: cover;
                    object-position: top left;
                }
            }
        }
    }
   &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include max(md) {
        flex-flow: column nowrap;
    }
   }
   &__picture {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    @include max(md) {
        width: 100%;
        justify-content: flex-start;
    }
    img {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }
   }
   &__descriotion {
    @include max(md) {
        margin-bottom: 20px;
    }
   }
   &__title {
    position: relative;
    display: flex;
    margin-bottom: 25px;
    @include max(md) {
        width: 100%;
        justify-content: center;
        
    }
    @include max(sm) {
        justify-content: flex-start;
        
    }
    br {
        @include max(md) {
            display: none;
        }
    }

   }
   &__text {
    color: #fff;
    @include max(sm) {
        font-size: 20px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0%;
        text-align: left; 
    }
    @include max(xs) {
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0%;
    }
   }
}
.buy-product {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-flow: column nowrap;
    margin-bottom: 224px;
    @include max(sm) {
        margin-bottom: 50px;
    }
    &__title {
        margin-bottom: 30px;
    }
    &__card {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        border-radius: 10px;
        box-shadow: 4px 4px 10px 0px rgba(7, 97, 125, 0.25);
        background: rgb(255, 255, 255); 
        padding: 20px 50px;
        margin-bottom: 24px;
        min-height: 194px;
        @include max(sm) {
            flex-flow: column nowrap;
            align-items: flex-start;
            padding: 30px;
            padding-left: 30px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &__number {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
            border-radius: 50%;
            color: #fff;
            padding-right: 14px;
            margin-right: 30px;
            background: linear-gradient(78.88deg, rgb(255, 8, 68) -75.122%,rgb(255, 177, 153) 127.906%);
            @include max(sm) {
                margin-bottom: 30px;
            }       
        }
        &__description {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            max-width: 951px;
            flex-flow: column nowrap;
        }
        &__title {
            margin-bottom: 7px;
        }
    }
}
.bank-offers {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-flow: column nowrap;
    margin-bottom: 103px;
    @include max(sm) {
        margin-bottom: 50px;
    }
    &__title {
        margin-bottom: 60px;
        @include max(sm) {
            margin-bottom: 30px;
        }
    }
    .bank-offer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        margin-bottom: 60px;
        @include max(xl) {
            flex-flow: column nowrap;
        }
        @include max(sm) {
            margin-bottom: 24px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &--reverse {
            flex-flow: row-reverse nowrap;
        }
        &__col {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 50%;
            flex-flow: column nowrap;
            border-radius: 10px;
            @include max(xl) {
                width: 100%;
            }
            &:nth-child(2) {
                border-radius: 10px;
                box-shadow: 4px 4px 10px 0px rgba(7, 97, 125, 0.25);
                background: rgb(255, 255, 255);
                padding: 20px;
                padding-left: 90px;
                @include max(xl) {
                    padding-left: 30px;
                }
                @include max(sm) {
                    padding: 20px;
                    padding-left: 30px;
                }
            }
        }
        &__desc {
            position: absolute;
            &__title {
                color: rgb(51, 51, 51);
                font-family: Tinkoff Sans;
                font-size: 47.27px;
                font-weight: 700;
                line-height: 110%;
                letter-spacing: 0%;
                text-align: left;                
            }
        }
        &__picture {
            position: relative;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            height: 100%;
            &::before {
                position: relative;
                display: block;
                width: 100%;
                content: "";
                padding-top: 57.14%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

            }
        }
        &__list {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: column nowrap;
            width: 100%;
            margin-bottom: 30px;
            ul {
                position: relative;
                display: block;
                width: 100%;
                padding-left: 28px;
                padding-right: 28px;
                padding-top: 0;
                margin-top: 0;
                color: $fontColor;
                font-size: 17px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0px;
                text-align: left;
                @include max(sm) {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0%;
                    text-align: left;
                    padding-right: 0;
                }
                li {
                    position: relative;
                    list-style-type: none;
                    color: $fontColor;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 28px;
                    letter-spacing: 0px;
                    text-align: left;
                    margin-bottom: 16px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    @include max(sm) {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17px;
                        letter-spacing: 0%;
                        text-align: left;
                        margin-bottom: 10px;
                    }
                    &::before {
                        content: '';
                        display: block;
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        background-color: rgb(255, 221, 45);
                        left: -20px;
                        top: 8px;
                        position: absolute;
                        @include max(sm) {
                            // height: vw2(8);
                            // width: vw2(8);
                            // left: vw2(-15);
                            // top: vw2(10);
                        }
                    }
                    a {
                        position: relative;
                        display: inline-block;
                        text-decoration: underline;
                        color: $fontColor;
                        font-size: 17px;
                        font-weight: 400;
                        line-height: 28px;
                        letter-spacing: 0px;
                        text-align: left;
                        transition: all 0.3s;
                        @include max(sm) {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 17px;
                            letter-spacing: 0%;
                            text-align: left;
                        }
                        &:hover {
                            transition: all 0.3s;
                            text-decoration: none;   
                        }
                        
                    } 
                }
              }
            &--line {
                ul {
                    padding-left: 0;
                    li {
                        &::before {
                            display: none;
                            @include max(sm) {
                                // height: vw2(8);
                                // width: vw2(8);
                                // left: vw2(-15);
                                // top: vw2(10);
                            }
                        } 

                    }
                }
            }
        }
    }
}

.delivery-page {

}
.delivery-terms {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column nowrap;
    width: 100%;
    border-radius: 10px;
    padding: 80px 50px;
    margin-bottom: 30px;
    @include max(xl) {
        padding: 50px 30px;  
    }
    @include max(sm) {
        padding: 30px 20px; 
    }
    &__title {
        margin-bottom: 40px;
        @include max(sm) {
            width: 100%;
            text-align: center;
        }
    }
    &__row {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        @include max(xl) {
            flex-flow: column nowrap;
        }
    }
    &__col {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        &:nth-child(1) {
            justify-content: flex-end;
            width: 34%;
            padding-right: 20px;
            @media (max-width: 1450px) {
                width: 24%;
            }
            @include max(xl) {
                flex-flow: column nowrap;
            }
            @include max(xl) {
                width: 100%;
                margin-bottom: 50px;
            }
            @include max(sm) {
                align-items: center;
                padding-right: 0;
            }
        }
        &:nth-child(2) {
            width: 66%;
            @media (max-width: 1450px) {
                width: 76%;
            }
            @include max(xl) {
                width: 100%;
                
            }
        }
    }
    &__list {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: column nowrap;
        width: 100%;
        margin-bottom: 23px;
        ul {
            position: relative;
            display: block;
            width: 100%;
            padding-left: 28px;
            padding-right: 28px;
            padding-top: 0;
            margin-top: 0;
            color: $fontColor;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0px;
            text-align: left;
            @include max(sm) {
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0%;
                text-align: left;
                padding-right: 0;
            }
            li {
                position: relative;
                list-style-type: none;
                color: $fontColor;
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: 0px;
                text-align: left;
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
                @include max(sm) {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0%;
                    text-align: left;
                    margin-bottom: 10px;
                }
                &::before {
                    content: '';
                    display: block;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background-color: rgb(255, 221, 45);
                    left: -20px;
                    top: 8px;
                    position: absolute;
                    @include max(sm) {
                        // height: vw2(8);
                        // width: vw2(8);
                        // left: vw2(-15);
                        // top: vw2(10);
                    }
                }
                a {
                    position: relative;
                    display: inline-block;
                    text-decoration: underline;
                    color: $fontColor;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 28px;
                    letter-spacing: 0px;
                    text-align: left;
                    transition: all 0.3s;
                    @include max(sm) {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17px;
                        letter-spacing: 0%;
                        text-align: left;
                    }
                    &:hover {
                        transition: all 0.3s;
                        text-decoration: none;   
                    }
                    
                } 
            }
          }
        &--line {
            ul {
                padding-left: 0;
                padding-right: 0;
                li {
                    &::before {
                        display: none;
                    } 

                }
            }
        }
    }
    &__picture  {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &--one {
            bottom: -35px;
            @include max(xl) {
                bottom: 0;
            }
        }
        img {
            position: relative;
            display: block;
            width: 100%;
        }
    }
    &__logo  {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 25px;
        img {
            position: relative;
            display: block;
        }
    }
    &__advantages {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-top: 75px;
        @include max(xl) {
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
        }
    }
    &__advantage {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 328px;
        @include max(xl) {
            min-width: 350px;
            margin-bottom: 30px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &__icon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 145px;
            height: 145px;
            min-width: 145px;
            min-height: 145px;
            margin-bottom: 20px;
            @include max(xl) {
                width: 120px;
                height: 120px;
                min-width: 120px;
                min-height: 120px;
            }
            @include max(xl) {
                width: 120px;
                height: 120px;
                min-width: 120px;
                min-height: 120px;
            }
            @include max(sm) {
                width: 80px;
                height: 80px;
                min-width: 80px;
                min-height: 80px;
            }
            img {
                position: relative;
                display: block;
                object-fit: contain;
                object-position: center center;
                width: 100%;
                height: 100%;
            }
        }
        &__text {
            position: relative;
            display: inline-block;
            text-align: center;
        }
    }
}
.delivery-pickup {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column nowrap;
    width: 100%;
    border-radius: 10px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(179.28deg, rgba(162, 240, 252, 0) 55.131%,rgba(202, 33, 71, 0.15) 97.551%);    
    padding: 80px 50px;
    margin-bottom: 30px;
    @include max(xl) {
        padding: 50px 30px;  
    }
    @include max(sm) {
        padding: 30px 20px; 
    }
    &__title {
        margin-bottom: 40px;
        @include max(sm) {
            width: 100%;
            text-align: center;
        }
    }
    &__row {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        @include max(xl) {
            flex-flow: column nowrap;
        }
    }
    &__col {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        &:nth-child(1) {
            justify-content: flex-end;
            width: 34%;
            padding-right: 20px;
            @media (max-width: 1450px) {
                width: 24%;
            }
            @include max(xl) {
                width: 100%;
                margin-bottom: 50px;
            }
            @include max(sm) {
                align-items: center;
                padding-right: 0;
            }
        }
        &:nth-child(2) {
            width: 66%;
            @media (max-width: 1450px) {
                width: 76%;
            }
            @include max(xl) {
                width: 100%;
                
            }
        }
    }
    &__addresses {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: column nowrap;
        width: 100%;
        margin-bottom: 50px;

    }
    &__address {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        min-height: 70px;
        padding: 6px;
        border-bottom: 1px solid rgb(217, 217, 217);

        &:first-child {
            border-top: 1px solid rgb(217, 217, 217);
        }
        @include max(sm) {
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 15px;
            padding-left: 0;
        }
        &__title {
            position: relative;
            display: inline-block;
            width: 24.6%;
            color: rgb(51, 51, 51);
        
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0%;
            text-align: left;
            @include max(sm) {
                font-size: 17px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0%;
                width: 100%;
                margin-bottom: 5px;
            }
        }
        &__text {
            position: relative;
            display: inline-block;
            width: 46.5%;
            color: rgb(51, 51, 51);
        
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0%;
            text-align: left;
            @include max(sm) {
                font-size: 15px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0%;
                width: 100%;
                margin-bottom: 15px;
            }
        }
        &__button  {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 28.9%;
            @include max(sm) {
                width: 100%;
            }
        }
    }
    &__picture  {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &--one {
            bottom: -60px;
            @include max(xl) {
                bottom: 0;
            }
        }
        img {
            position: relative;
            display: block;
            width: 100%;
        }
    }
    &__logo  {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 25px;
        img {
            position: relative;
            display: block;
        }
    }
    &__map  {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        filter: grayscale(100%);
        &__wrap {
            position: relative;
            display: flex;
            width: 100%;
            border-radius: 10px;
            overflow: hidden;
            &::before {
                position: relative;
                display: block;
                width: 100%;
                content: '';
                padding-top: 32.72%;
                @include max(sm) {
                    padding-top: 50%;
                }
                @include max(xs) {
                    padding-top: 75%;
                }
            }
        }

    }
}

.ymaps-2-1-79-ground-pane, .ymaps-b-global {
    filter: grayscale(100%);
}

.delivery-russia {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column nowrap;
    width: 100%;
    border-radius: 30px;
    box-shadow: 4px 4px 10px 0px rgba(37, 37, 37, 0.25);
    background: linear-gradient(136.26deg, rgb(250, 252, 251) 3.339%,rgb(183, 221, 198) 115.853%);
    padding: 80px 50px;
    margin-bottom: 90px;
    @include max(xl) {
        padding: 50px 30px;  
    }
    @include max(sm) {
        padding: 30px 20px; 
    }
    &__title {
        margin-bottom: 40px;
        @include max(sm) {
            width: 100%;
            text-align: center;
        }
    }
    &__row {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        @include max(xl) {
            flex-flow: column nowrap;
        }
    }
    &__col {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        &:nth-child(1) {
            justify-content: flex-end;
            width: 34%;
            padding-right: 20px;
            @media (max-width: 1450px) {
                width: 24%;
            }
            @include max(xl) {
                width: 100%;
                margin-bottom: 50px;
            }
            @include max(sm) {
                align-items: center;
                padding-right: 0;
            }
        }
        &:nth-child(2) {
            width: 66%;
            @media (max-width: 1450px) {
                width: 76%;
            }
            @include max(xl) {
                width: 100%;
                
            }
        }
    }
    &__list {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: column nowrap;
        width: 100%;
        margin-bottom: 23px;
        ul {
            position: relative;
            display: block;
            width: 100%;
            padding-left: 28px;
            padding-right: 28px;
            padding-top: 0;
            margin-top: 0;
            color: $fontColor;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0px;
            text-align: left;
            @include max(sm) {
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0%;
                text-align: left;
                padding-right: 0;
            }
            li {
                position: relative;
                list-style-type: none;
                color: $fontColor;
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: 0px;
                text-align: left;
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
                @include max(sm) {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0%;
                    text-align: left;
                    margin-bottom: 10px;
                }
                &::before {
                    content: '';
                    display: block;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background-color: rgb(255, 221, 45);
                    left: -20px;
                    top: 8px;
                    position: absolute;
                    @include max(sm) {
                        // height: vw2(8);
                        // width: vw2(8);
                        // left: vw2(-15);
                        // top: vw2(10);
                    }
                }
                a {
                    position: relative;
                    display: inline-block;
                    text-decoration: underline;
                    color: $fontColor;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 28px;
                    letter-spacing: 0px;
                    text-align: left;
                    transition: all 0.3s;
                    @include max(sm) {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17px;
                        letter-spacing: 0%;
                        text-align: left;
                    }
                    &:hover {
                        transition: all 0.3s;
                        text-decoration: none;   
                    }
                    
                } 
            }
          }
        &--line {
            ul {
                padding-left: 0;
                padding-right: 0;
                li {
                    &::before {
                        display: none;
                    } 

                }
            }
        }
    }
    &__picture  {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &--one {
            bottom: -35px;
            @include max(xl) {
                bottom: 0;
            }
        }
        img {
            position: relative;
            display: block;
            width: 100%;
        }
    }
    &__logo  {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 25px;
        img {
            position: relative;
            display: block;
        }
    }
}

.refund-product {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-flow: column nowrap;
    margin-bottom: 103px;
    @include max(sm) {
        margin-bottom: 50px;
    }
    &__title {
        margin-bottom: 30px;
        @include max(md) {
           margin-bottom: 30px;
        }
    }
    &__card {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        border-radius: 10px;
        box-shadow: 4px 4px 10px 0px rgba(7, 97, 125, 0.25);
        background: rgb(255, 255, 255); 
        padding: 50px;
        margin-bottom: 24px;
        min-height: 194px;
        @include max(sm) {
            flex-flow: column nowrap;
            align-items: flex-start;
            padding: 30px;
            padding-left: 30px;
        }
        @include max(xs) {
            padding: 20px;
            padding-left: 20px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &__number {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
            border-radius: 50%;
            color: #fff;
            padding-right: 0px;
            margin-right: 30px;
            background: linear-gradient(78.88deg, rgb(255, 8, 68) -75.122%,rgb(255, 177, 153) 127.906%);
            @include max(sm) {
                margin-bottom: 30px;
            }       
        }
        &__description {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            max-width: 1124px;
            flex-flow: column nowrap;
        }
        &__title {
            margin-bottom: 15px;
        }
        &__list {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: column nowrap;
            width: 100%;
            margin-bottom: 0px;
            padding-left: 0px;
            ul {
                position: relative;
                display: block;
                width: 100%;
                padding-left: 28px;
                padding-right: 28px;
                padding-top: 0;
                margin-top: 0;
                color: $fontColor;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0%;
                text-align: left;
                @include max(sm) {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0%;
                }
                li {
                    position: relative;
                    list-style-type: none;
                    color: $fontColor;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0%;
                    text-align: left;
                    @include max(sm) {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 25px;
                        letter-spacing: 0%;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &::before {
                        content: '';
                        display: block;
                        height: 5px;
                        width: 5px;
                        border-radius: 50%;
                        background-color: $fontColor;
                        left: -20px;
                        top: 13px;
                        position: absolute;
                        @include max(sm) {
                            // height: vw2(8);
                            // width: vw2(8);
                            // left: vw2(-15);
                            // top: vw2(10);
                        }
                    }
                }
              }
            &--line {
                padding-left: 15px;
                ul {
                    padding-left: 0;
                    li {
                        &::before {
                            display: none;
                            @include max(sm) {
                                // height: vw2(8);
                                // width: vw2(8);
                                // left: vw2(-15);
                                // top: vw2(10);
                            }
                        } 

                    }
                }
            }
        }
    }
    &__documents {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start; 
      width: 100%;
      padding-left: 90px;
      flex-flow: column nowrap;
      @include max(xl) {
        padding-left: 50px;
      }
      @include max(md) {
        padding-left: 0;
      }
      &__buttons {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 70px;
        @include max(xl) {
            flex-flow: column nowrap;
        }
        @include max(sm) {
            margin-bottom: 50px;
        }
      }
      &__list {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: column nowrap;
        width: 100%;
        margin-bottom: 0px;
        padding-left: 0px;
        margin-bottom: 30px;
        max-width: 1191px;
        ul {
            position: relative;
            display: block;
            width: 100%;
            padding-left: 28px;
            padding-right: 28px;
            padding-top: 0;
            margin-top: 0;
            color: $fontColor;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0%;
            text-align: left;
            @include max(sm) {
                font-size: 15px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0%;
            }
            li {
                position: relative;
                list-style-type: none;
                color: $fontColor;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0%;
                text-align: left;
                @include max(sm) {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0%;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    content: '';
                    display: block;
                    height: 5px;
                    width: 5px;
                    border-radius: 50%;
                    background-color: $fontColor;
                    left: -20px;
                    top: 13px;
                    position: absolute;
                    @include max(sm) {
                        // height: vw2(8);
                        // width: vw2(8);
                        // left: vw2(-15);
                        // top: vw2(10);
                    }
                }
            }
          }
        &--line {
            padding-left: 15px;
            ul {
                padding-left: 0;
                li {
                    &::before {
                        display: none;
                        @include max(sm) {
                            // height: vw2(8);
                            // width: vw2(8);
                            // left: vw2(-15);
                            // top: vw2(10);
                        }
                    } 

                }
            }
        }
        }
    }
    &__document {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;
        color: rgb(51, 51, 51); 
        font-size: 16px;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 0%;
        text-align: left;
        transition: all 0.3s;
        margin-right: 40px;
        @include max(xl) {
            margin-right: 0;
            margin-bottom: 30px;
        }
        @include max(sm) {
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0%;
            margin-bottom: 20px;
        }
        &__icon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 9px;
            svg {
                .color {
                    transition: all 0.3s;
                }
            }
        }
        &__text {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        &:hover {
            transition: all 0.3s;
            color: rgb(202, 33, 71); 
            svg {
                .color {
                  fill: rgb(202, 33, 71);
                }
            }
        }
      } 
}
.payment {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-flow: column nowrap;
    margin-bottom: 236px;
    @include max(sm) {
        margin-bottom: 50px;
    }
    &__title {
        margin-bottom: 30px;
        @include max(md) {
           margin-bottom: 30px;
        }
    }
    &__subtitle {
        margin-bottom: 30px;
        @include max(md) {
           margin-bottom: 30px;
        }
    }
    &__row {
        margin-bottom: 50px;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column nowrap;
        width: 100%;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__card {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        border-radius: 10px;
        box-shadow: 4px 4px 10px 0px rgba(7, 97, 125, 0.25);
        background: rgb(255, 255, 255); 
        padding: 50px;
        margin-bottom: 24px;
        min-height: 194px;
        @include max(sm) {
            flex-flow: column nowrap;
            align-items: flex-start;
            padding: 30px;
            padding-left: 30px;
        }
        @include max(xs) {
            padding: 20px;
            padding-left: 20px;
        }
        &--opacity {
            border-radius: 10px;
            box-shadow: none;
            background: transparent; 
            padding: 0px;
            margin-bottom: 30px;
            min-height: auto; 
            padding-right: 20px; 
            @include max(sm) {
                padding-top: 0;
                padding-bottom: 0;
            }         
            @include max(xs) {
                padding: 10px;
                padding-top: 0;
                padding-bottom: 0;
            }         
        }
        &:last-child {
            margin-bottom: 0;
        }
        &__number {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
            border-radius: 50%;
            color: #fff;
            padding-right: 14px;
            margin-right: 30px;
            background: linear-gradient(78.88deg, rgb(255, 8, 68) -75.122%,rgb(255, 177, 153) 127.906%);
            @include max(sm) {
                margin-bottom: 20px;
            }
            &--opacity {
                opacity: 0;
                @include max(sm) {
                    display: none;
                }
            }      
        }
        &__description {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            max-width: 1124px;
            flex-flow: column nowrap;
        }
        &__title {
            margin-bottom: 15px;
        }
        &__list {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: column nowrap;
            width: 100%;
            margin-bottom: 0px;
            padding-left: 0px;
            ul {
                position: relative;
                display: block;
                width: 100%;
                padding-left: 28px;
                padding-right: 28px;
                padding-top: 0;
                margin-top: 0;
                color: $fontColor;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0%;
                text-align: left;
                @include max(sm) {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0%;
                }
                li {
                    position: relative;
                    list-style-type: none;
                    color: $fontColor;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0%;
                    text-align: left;
                    @include max(sm) {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 25px;
                        letter-spacing: 0%;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &::before {
                        content: '';
                        display: block;
                        height: 5px;
                        width: 5px;
                        border-radius: 50%;
                        background-color: $fontColor;
                        left: -20px;
                        top: 13px;
                        position: absolute;
                        @include max(sm) {
                            // height: vw2(8);
                            // width: vw2(8);
                            // left: vw2(-15);
                            // top: vw2(10);
                        }
                    }
                }
              }
            &--line {
                padding-left: 15px;
                ul {
                    padding-left: 0;
                    li {
                        &::before {
                            display: none;
                            @include max(sm) {
                                // height: vw2(8);
                                // width: vw2(8);
                                // left: vw2(-15);
                                // top: vw2(10);
                            }
                        } 

                    }
                }
            }
        }
    }
}
.warranty {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-flow: column nowrap;
    margin-bottom: 120px;
    @include max(sm) {
        margin-bottom: 50px;
    }
    &__title {
        margin-bottom: 30px;
        @include max(md) {
           margin-bottom: 30px;
        }
    }
    &__card {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        border-radius: 10px;
        box-shadow: 4px 4px 10px 0px rgba(7, 97, 125, 0.25);
        background: rgb(255, 255, 255); 
        padding: 50px;
        margin-bottom: 24px;
        min-height: 194px;
        @include max(sm) {
            flex-flow: column nowrap;
            align-items: flex-start;
            padding: 30px;
            padding-left: 30px;
        }
        @include max(xs) {
            padding: 20px;
            padding-left: 20px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &__number {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
            border-radius: 50%;
            color: #fff;
            padding-right: 0px;
            margin-right: 30px;
            background: linear-gradient(78.88deg, rgb(255, 8, 68) -75.122%,rgb(255, 177, 153) 127.906%);
            @include max(sm) {
                margin-bottom: 30px;
            }       
        }
        &__description {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            max-width: 1124px;
            flex-flow: column nowrap;
        }
        &__title {
            margin-bottom: 15px;
        }
        &__text {
            margin-bottom: 30px;
            @include max(md) {
                margin-bottom: 20px;
            }
            @include max(sm) {
                margin-bottom: 15px;
            }
            &--no-margin {
                margin-bottom: 0;
            }
        }
        &__list {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: column nowrap;
            width: 100%;
            margin-bottom: 0px;
            padding-left: 0px;
            ul {
                position: relative;
                display: block;
                width: 100%;
                padding-left: 28px;
                padding-right: 28px;
                padding-top: 0;
                margin-top: 0;
                color: $fontColor;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0%;
                text-align: left;
                @include max(sm) {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0%;
                }
                li {
                    position: relative;
                    list-style-type: none;
                    color: $fontColor;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0%;
                    text-align: left;
                    @include max(sm) {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 25px;
                        letter-spacing: 0%;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &::before {
                        content: '';
                        display: block;
                        height: 5px;
                        width: 5px;
                        border-radius: 50%;
                        background-color: $fontColor;
                        left: -20px;
                        top: 13px;
                        position: absolute;
                        @include max(sm) {
                            // height: vw2(8);
                            // width: vw2(8);
                            // left: vw2(-15);
                            // top: vw2(10);
                        }
                    }
                }
              }
            &--line {
                padding-left: 15px;
                ul {
                    padding-left: 0;
                    li {
                        &::before {
                            display: none;
                            @include max(sm) {
                                // height: vw2(8);
                                // width: vw2(8);
                                // left: vw2(-15);
                                // top: vw2(10);
                            }
                        } 

                    }
                }
            }
        }
    }
    &__documents {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start; 
      width: 100%;
      padding-left: 90px;
      flex-flow: column nowrap;
      @include max(xl) {
        padding-left: 50px;
      }
      @include max(md) {
        padding-left: 0;
      }
      &__buttons {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 70px;
        @include max(xl) {
            flex-flow: column nowrap;
        }
        @include max(sm) {
            margin-bottom: 50px;
        }
      }
      &__list {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: column nowrap;
        width: 100%;
        margin-bottom: 0px;
        padding-left: 0px;
        margin-bottom: 30px;
        max-width: 1191px;
        ul {
            position: relative;
            display: block;
            width: 100%;
            padding-left: 28px;
            padding-right: 28px;
            padding-top: 0;
            margin-top: 0;
            color: $fontColor;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0%;
            text-align: left;
            @include max(sm) {
                font-size: 15px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0%;
            }
            li {
                position: relative;
                list-style-type: none;
                color: $fontColor;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0%;
                text-align: left;
                @include max(sm) {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0%;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    content: '';
                    display: block;
                    height: 5px;
                    width: 5px;
                    border-radius: 50%;
                    background-color: $fontColor;
                    left: -20px;
                    top: 13px;
                    position: absolute;
                    @include max(sm) {
                        // height: vw2(8);
                        // width: vw2(8);
                        // left: vw2(-15);
                        // top: vw2(10);
                    }
                }
            }
          }
        &--line {
            padding-left: 15px;
            ul {
                padding-left: 0;
                li {
                    &::before {
                        display: none;
                        @include max(sm) {
                            // height: vw2(8);
                            // width: vw2(8);
                            // left: vw2(-15);
                            // top: vw2(10);
                        }
                    } 

                }
            }
        }
        }
    }
    &__document {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;
        color: rgb(51, 51, 51); 
        font-size: 16px;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 0%;
        text-align: left;
        transition: all 0.3s;
        margin-right: 40px;
        @include max(xl) {
            margin-right: 0;
            margin-bottom: 30px;
        }
        @include max(sm) {
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0%;
            margin-bottom: 20px;
        }
        &__icon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 9px;
            svg {
                .color {
                    transition: all 0.3s;
                }
            }
        }
        &__text {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        &:hover {
            transition: all 0.3s;
            color: rgb(202, 33, 71); 
            svg {
                .color {
                  fill: rgb(202, 33, 71);
                }
            }
        }
      } 
}
.work-plan {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-flow: column nowrap;
    margin-bottom: 64px;
    @include max(sm) {
        margin-bottom: 40px;
    }
    &__title {
        margin-bottom: 30px;
        @include max(sm) {
            margin-bottom: 20px;
        }
    }
    &__text {
        margin-bottom: 40px;
        @include max(sm) {
            margin-bottom: 30px;
        }
    }
    &__list {
        position: relative;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        width: 100%;
        @include max(sm) {
            flex-flow: row wrap;
        }
    }
    &__item {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: column nowrap;
        width: 100%;
        max-width: 240px;
        @include max(sm) {
            margin-bottom: 30px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &__icon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 24px;
            @include max(sm) {
                margin-bottom: 10px;
            }
        }
        &__text {
            text-align: center;
            color: rgb(51, 51, 51);
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
        }
    }
}
.without-overpayments {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-flow: column nowrap;
    margin-bottom: 103px;
    @include max(md) {
        margin-bottom: 50px;
    }
    &__title {
        margin-bottom: 30px;
        @include max(sm) {
            margin-bottom: 20px;
        }
    }
    .overpayments {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        margin-bottom: 60px;
        @include max(md) {
            flex-flow: column nowrap;
        }
        @include max(sm) {
            margin-bottom: 24px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &--reverse {
            flex-flow: row-reverse nowrap;
        }
        &__col {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 50%;
            flex-flow: column nowrap;
            border-radius: 10px;
            @include max(md) {
                width: 100%;
            }
            &:nth-child(1) {
                @include max(md) {
                    margin-bottom: 30px;
                }
            }
            &:nth-child(2) {
                padding: 20px;
                padding-left: 30px;
                padding-top: 0;
                @include max(md) {
                    padding-left: 00px;
                    padding-top: 0;
                }
                @include max(sm) {
                    padding: 20px;
                    padding-left: 00px;
                    padding-top: 0;
                }
            }
        }
        &__picture {
            position: relative;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            height: 100%;
            &::before {
                position: relative;
                display: block;
                width: 100%;
                content: "";
                padding-top: 51.64%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

            }
        }
        &__list {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: column nowrap;
            width: 100%;
            margin-bottom: 0px;
            ul {
                position: relative;
                display: block;
                width: 100%;
                padding-left: 28px;
                padding-right: 28px;
                padding-top: 0;
                margin-top: 0;
                color: $fontColor;
                font-size: 17px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0px;
                text-align: left;
                @include max(sm) {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0%;
                    text-align: left;
                    padding-right: 0;
                }
                li {
                    position: relative;
                    list-style-type: none;
                    color: $fontColor;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 28px;
                    letter-spacing: 0px;
                    text-align: left;
                    margin-bottom: 16px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    @include max(sm) {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17px;
                        letter-spacing: 0%;
                        text-align: left;
                        margin-bottom: 10px;
                    }
                    &::before {
                        content: '';
                        display: block;
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        background-color: rgb(255, 221, 45);
                        left: -20px;
                        top: 8px;
                        position: absolute;
                        @include max(sm) {
                            // height: vw2(8);
                            // width: vw2(8);
                            // left: vw2(-15);
                            // top: vw2(10);
                        }
                    }
                    a {
                        position: relative;
                        display: inline-block;
                        text-decoration: underline;
                        color: $fontColor;
                        font-size: 17px;
                        font-weight: 400;
                        line-height: 28px;
                        letter-spacing: 0px;
                        text-align: left;
                        transition: all 0.3s;
                        @include max(sm) {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 17px;
                            letter-spacing: 0%;
                            text-align: left;
                        }
                        &:hover {
                            transition: all 0.3s;
                            text-decoration: none;   
                        }
                        
                    } 
                }
              }
            &--line {
                ul {
                    padding-left: 0;
                    li {
                        &::before {
                            display: none;
                            @include max(sm) {
                                // height: vw2(8);
                                // width: vw2(8);
                                // left: vw2(-15);
                                // top: vw2(10);
                            }
                        } 

                    }
                }
            }
        }
    }
}
.installmants{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column nowrap;
    width: 100%;
    margin-bottom: 96px;
    &__are {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column nowrap;
        width: 100%;
        max-width: 1920px;
        padding: 32px;
        background-image: url(../img/nstallments-are__bg.png);
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 208px;
        border-radius: 8px;
        overflow: hidden;
        // background: rgb(245, 245, 245);
        margin-bottom: 40px;
        @include max(md) {
            padding: 30px;
            margin-bottom: 30px;
            background-position: top center;
        }
        &__title {
            margin-bottom: 20px;
            @include max(md) {
                margin-bottom: 10px;
            }
        }
        &__text {
            width: 100%;
            max-width: 538px;
            @include max(md) {
            }
        }
    }
    &__desc {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 58px;
        @include max(md) {
            flex-flow: column nowrap;
        }
        &__col {
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            width: 50%;
            padding-right: 40px;
            @include max(md) {
                width: 100%;
                padding-right: 10px;
            }
            &:nth-child(1) {
                @include max(md) {
                    margin-bottom: 30px;
                }
            }
        }
        &__title {
            font-weight: 700;
            margin-bottom: 16px;
            width: 100%;
            max-width: 528px;
            @include max(md) {
                font-weight: 700;
                max-width: 100%;
                font-size: 16px;
            }
            @include max(sm) {
                font-weight: 700;
                max-width: 100%;
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
        &__text {
            width: 100%;
            max-width: 528px;
            @include max(md) {
                max-width: 100%;
            }
            @include max(sm) {
                max-width: 100%;
            }
        }
    }
    &__text {
        width: 100%;
        max-width: 865px;
        margin-bottom: 108px;
        @include max(md) {
            margin-bottom: 60px;
        }
    }
    &__rules {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        border: 1px solid rgb(234, 236, 238);
        padding: 32px;
        max-width: 1104px;
        @include max(xs) {
            padding: 20px;
        }
        &__text {
            position: relative;
            display: inline-block;
            color: rgb(51, 51, 51);
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left; 
            width: 100%;
            max-width: 920px;
            &::before {
                position: absolute;
                top: -10px;
                left: -10px;
                content: "1";
                color: rgb(51, 51, 51);
                font-size: 11px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: left;
            }
        }
    }
}
.sber-advantages {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 96px;
    @include max(xl) {
        flex-flow: row wrap;
        justify-content: center;
    }
    @include max(md) {
        margin-bottom: 50px;
    }
    .sber-advantage {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: column nowrap;
        width: 100%;
        max-width: 446px;
        @include max(xl) {
            margin-bottom: 50px;
        }
        &:last-child {
            @include max(xl) {
                margin-bottom: 0px;
            }
        }
        &__icon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
        }
        &__title {
            position: relative;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            margin-bottom: 10px;
        }
        &__text {
            text-align: center;
            font-weight: 500;
        }
    }
}
.sber-buying {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;    
    width: 100%;
    border-radius: 10px;
    background: rgb(245, 246, 248);
    overflow: hidden;
    margin-bottom: 70px;
    @include max(md) {
        margin-bottom: 50px;
        flex-flow: column-reverse nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;
        padding-bottom: 0;
    }
    &__col {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column nowrap;
        &:nth-child(1) {
            width: 42.4%;
            justify-content: flex-end;
            align-items: flex-end;
            @include max(md) {
                width: 100%;
                justify-content: center;
                align-items: center;
            }
        }
        &:nth-child(2) {
            padding-left: 60px;
            justify-content: center;
            width: 57.60%;
            @include max(md) {
                width: 100%;
            }
            @include max(sm) {
                padding-left: 0;
            }
        }
    }
    &__list {
        padding-left: 45px;
        @include max(md) {
            padding-left: 10px;
        }
        &__title {
            margin-bottom: 30px;
            @include max(md) {
                margin-bottom: 20px;
            }
        }
        ul {
            position: relative;
            display: block;
            width: 100%;
            padding-left: 28px;
            padding-right: 28px;
            padding-top: 0;
            margin-top: 0;
            color: $fontColor;
            font-size: 17px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0px;
            text-align: left;
            @include max(sm) {
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0%;
                text-align: left;
                padding-right: 0;
            }
            li {
                position: relative;
                list-style-type: none;
                color: $fontColor;
                font-size: 17px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0px;
                text-align: left;
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                @include max(sm) {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0%;
                    text-align: left;
                }
                &::before {
                    content: '';
                    display: block;
                    height: 5px;
                    width: 5px;
                    border-radius: 50%;
                    background-color: $fontColor;
                    left: -20px;
                    top: 13px;
                    position: absolute;
                    @include max(sm) {
                        // height: vw2(8);
                        // width: vw2(8);
                        // left: vw2(-15);
                        // top: vw2(10);
                    }
                }
                a {
                    position: relative;
                    display: inline-block;
                    text-decoration: underline;
                    color: $fontColor;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 28px;
                    letter-spacing: 0px;
                    text-align: left;
                    transition: all 0.3s;
                    @include max(sm) {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17px;
                        letter-spacing: 0%;
                        text-align: left;
                    }
                    &:hover {
                        transition: all 0.3s;
                        text-decoration: none;   
                    }
                    
                } 
            }
          }
    }
    &__picture {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        @include max(md) {
            width: auto;
        }
        img {
            position: relative;
            display: flex;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            object-fit: contain;
            object-position: bottom right;
            width: 100%;
            max-width: 493px;
        }
    } 
}
.sber-difference {
    margin-bottom: 76px;
    @include max(md) {
        margin-bottom: 50px;
    }
    &__title {
       margin-bottom: 34px;
       @include max(sm) {
        margin-bottom: 15px;
    }
    }
    &__text {
        position: relative;
        display: inline-block;
        width: 100%;
        max-width: 1059px;
        color: $fontColor;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        @include max(sm) {
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0%;
        }
    }
}
.sber-method {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 80px;
    &__list {
        position: relative;
        display: flex;
        width: calc(100% + 40px);
        align-items: stretch;
        flex-flow: row wrap;
        margin-left: -40px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        @include max(lg) {
            width: calc(100% + 24px);
            margin-left: -24px;
        }
        @include max(md) {
            width: calc(100% + 0px);
            margin-left:  0px;
            flex-flow: column nowrap;
        }
    }
    &__item {
        position: relative;
		flex: 0 0 33.333%;
		max-width: calc(33.3333% - 40px);
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: flex-start;
		margin-left: 40px;
		margin-bottom: 0px;
		@include max(lg) {
			max-width: calc(33.3333% - 24px);
			margin-left: 24px;
		}
		@include max(md) {
			max-width: calc(100% - 0px);
			margin-left: 0px;
			margin-bottom: 100px;
		}
		@include max(sm) {
			flex-flow: column nowrap;
			justify-content: space-between;
			align-items: flex-start;
			margin-bottom: 80px;
		}
        &:last-child {
            margin-bottom: 0;
        }
        &__picture {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 104px;
            @include max(md) {
                margin-bottom: 30px;
            }
            &::before {
                position: relative;
                width: 100%;
                display: block;
                content: "";
                padding-top: 100%;

            }
            img {
                position: absolute;
                display: block;
                width: 100%;
                object-fit: cover;
                object-position: top center;
            }
        }
        &__title {
            position: relative;
            display: inline-block;
            text-align: center;
            width: 100%;
            margin-bottom: 8px;
            @include max(sm) {
                font-size: 24px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0%;
            }
        }
        &__text {
            position: relative;
            text-align: center;
            width: 100%;
            font-weight: 500;
        }
    }
}


.test---------buttons {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-flow: column nowrap;
    .btn-new {
        margin-bottom: 20px;
    }
}