$media-list: (
		'xxs': 370, 
		'xs': 576, 
		'sm': 768 ,
		'md': 992,  
		'lg': 1080,
		'xl': 1280,
		'xxl': 1920
);

/* шрифты */
$mainFont: 'Inter', sans-serif;

/* цвета */
$fontColor: rgb(51, 51, 51);
$blue: #2879B1;
$blueLight: #44C8F5;
$white: #fff;
